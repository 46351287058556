import React, {useEffect} from "react";
import Pagination from "react-bootstrap/Pagination";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {getAppConfig, setPagination} from "../../redux/slices/appconfigSlice";
import "./style.scss";
import PropTypes from "prop-types";
import useWindowDimensions from "../../utils/getWindowDimensions";
import {getPager} from "../../helpers/common";

const PaginationSection = ({_setPage, page}) => {
    const {t} = useTranslation();
    const disptach = useDispatch();
    const {width} = useWindowDimensions();
    const appconfig = useSelector(getAppConfig);

    useEffect(() => {
        if (width < 768) {
            disptach(setPagination(getPager(appconfig?.metaDataCount, page, 9, 5)));
        } else {
            disptach(setPagination(getPager(appconfig?.metaDataCount, page, 9, 10)));
        }
    }, [width]);

    return (
        <>
            {appconfig.offers?.length &&
            appconfig.pagination?.startPage !== appconfig.pagination?.endPage ? (
                <Pagination>
                    {page > 1 && (
                        <Pagination.Prev onClick={() => _setPage(page - 1)}>
                            <span className="next-prev">{t("Buttons.Prev")}</span>
                        </Pagination.Prev>
                    )}
                    {appconfig.pagination?.pages?.map((pageNumber, index) => {
                        return (
                            <Pagination.Item
                                key={index}
                                active={pageNumber === page}
                                onClick={() => _setPage(pageNumber)}
                            >
                                {pageNumber}
                            </Pagination.Item>
                        );
                    })}
                    {page < appconfig.pagination?.endPage && (
                        <Pagination.Next onClick={() => _setPage(page + 1)}>
                            <span className="next-prev">{t("Buttons.Next")}</span>
                        </Pagination.Next>
                    )}
                </Pagination>
            ) : (
                ""
            )}
        </>
    );
};

PaginationSection.propTypes = {
    _setPage: PropTypes.func,
    page: PropTypes.number,
};

export default PaginationSection;
