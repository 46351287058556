import axios from "axios";
import AppConfig from "../helpers/config";
// import store from "../redux";
const axiosApiInstance = axios.create();

export const post = (api, data, source) => {
  if (source) {
    const config = { cancelToken: source.token };
    return axiosApiInstance.post(api, data, config);
  }
  return axiosApiInstance.post(api, data);
};

export const postWithoutBody = (api) => {
  return axiosApiInstance.post(api);
};

export const get = (api) => {
  return axiosApiInstance.get(api);
};

axiosApiInstance.interceptors.request.use(
  async (config) => {
    const value = await localStorage.getItem("auth_token");
    config.headers = {
      Authorization: `Bearer ${value}`,
      "Content-Type": "application/json",
    };

    if (config.method === "post") {
      const local = JSON.parse(
        await localStorage.getItem("persist:gt-web:rootApp")
      );
      const currency = JSON.parse(local.loginconfig)?.currency?.code;

      if (
        config?.url?.includes("/SearchOnlyListing") ||
        config?.url?.includes("/GetPackageDetails") ||
        config?.url?.includes("/MoreFlightsSearch") ||
        config?.url?.includes("/InsertFavorite") ||
        config?.url?.includes("/DeleteFavorite")
      ) {
        const transaction_id = JSON.parse(local.appconfig)?.transaction_id;
        config.data = {
          ...config.data,
          transaction_id,
          currency,
        };
      }
      // if (config.data !== undefined && config.data !== null && config.data) {
      //   const parsedData =
      //     typeof config.data === "string"
      //       ? JSON.parse(config.data)
      //       : config.data;
      //   const modifiedData = {
      //     ...parsedData, // Preserve existing data properties
      //     currency, // Add a new key-value pair
      //   };
      //
      //   // Update the config with the modified JSON data
      //   config.data = JSON.stringify(modifiedData);
      // }
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    console.log({ error });
    const originalRequest = error.config;

    if (error?.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const res = await postWithoutBody(
        `${AppConfig.baseApiURL}/Authenticate/GetAccessToken`
      );
      await localStorage.setItem("auth_token", res.data.access_token);
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + res.data.access_token;
      return axiosApiInstance(originalRequest);
    }
    return Promise.reject(error);
  }
);
