import React from "react";
import { useTranslation } from "react-i18next";
import LoaderImg from "../../assets/images/Genius-sun-loader.gif";
import "./style.scss";

const Loader = () => {
  const { t } = useTranslation();

  return (
    <div className="loader show">
      <div className="loader-content">
        <img src={LoaderImg} className={'loader_spinner'} alt={'loading-image'}/>
        <p>{t("Loading")} ...</p>
      </div>
    </div>
  );
};

export default Loader;
