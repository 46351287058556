import "./styles.scss";
import React, { useEffect } from "react";
import Topbar from "../../components/sections/DetailsTopBar/DetailsTopBar";
import GoogleMapReact from "google-map-react";
import PropTypes from "prop-types";
import MapCardSlider from "../../components/sections/MapCardSlider/MapCardSlider";

const MapPage = () => {
  // const url = window.location.href;
  // const parts = url.split("/");

  // const hotelId = parts[parts.length - 2];

  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627,
    },
    zoom: 11,
  };

  useEffect(() => {
    window.scroll(0, 0);
    // console.log({ hotelId });
  }, []);

  return (
    <div className="main-wrapper flight-page">
      <main className="body-wrapper">
        <div style={{ height: "100vh", width: "100%" }}>
          <div className={"top-bar-container"}>
            <Topbar isMap={true} />
          </div>
          <GoogleMapReact
            bootstrapURLKeys={{ key: "" }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
          >
            <AnyReactComponent
              lat={59.955413}
              lng={30.337844}
              text={"My Marker"}
            />
          </GoogleMapReact>
          <MapCardSlider />
        </div>
      </main>
    </div>
  );
};

const AnyReactComponent = ({ text }) => <div>{text}</div>;

AnyReactComponent.propTypes = {
  text: PropTypes.string,
};

export default MapPage;
