import React from "react";
import "./style.scss";
import Slider from "react-slick";
import Image1 from "../../../assets/images/hotal-1.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    // faHeart,
    faMapMarkerAlt,
    faStar,
} from "@fortawesome/pro-solid-svg-icons";
import BadgeIcon from "../../../assets/images/icons/aroplane-bed.svg";
import {useSelector} from "react-redux";
import {getAppConfig} from "../../../redux/slices/appconfigSlice";
import {getCurrencyBadge} from "../../../helpers/common";

function MapCardSlider() {
    const appConfig = useSelector(getAppConfig);

    const settings = {
        className: "center",
        dots: false,
        centerMode: true,
        // infinite: 3,
        centerPadding: "150px",
        slidesToShow: 9,
        slidesToScroll: 1,
        swipeToSlide: true,
        speed: 200,
        initialSlide: 3,
        arrows: false,
        responsive: [
            {
                breakpoint: 3400,
                settings: {
                    slidesToShow: 8,
                    centerPadding: "50px",
                },
            },
            {
                breakpoint: 2900,
                settings: {
                    slidesToShow: 7,
                    centerPadding: "50px",
                },
            },
            {
                breakpoint: 2550,
                settings: {
                    slidesToShow: 6,
                    centerPadding: "50px",
                },
            },
            {
                breakpoint: 2130,
                settings: {
                    slidesToShow: 5,
                    centerPadding: "150px",
                },
            },
            {
                breakpoint: 1800,
                settings: {
                    slidesToShow: 4,
                    centerPadding: "150px",
                },
            },
            {
                breakpoint: 1530,
                settings: {
                    slidesToShow: 4,
                    centerPadding: "50px",
                },
            },
            {
                breakpoint: 1400,
                settings: {
                    slidesToShow: 3,
                    centerPadding: "100px",
                },
            },
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 2,
                    centerPadding: "100px",
                },
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 1,
                    centerPadding: "200px",
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    centerPadding: "100px",
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    centerPadding: "50px",
                },
            },
        ],
    };

    return (
        <>
            <div className="mapcards-slider">
                <Slider {...settings}>

                    {appConfig.offers?.map((offer, index) => (
                        <div key={index} className="packages-card-grid">
                            <div className="packages-card-header">
                                <img
                                    src={offer?.thumbnailUrl || Image1}
                                    className="covered-image"
                                    alt={"covered-icon"}
                                />
                                <div className="card-badge">
                                    <img src={BadgeIcon} alt={"badge-icon"}/>
                                    <span>Hotel Incl Flight</span>
                                </div>
                            </div>
                            <div className="card-middle-area">
                                <div className="packages-card-Body">
                                    <h4 className="heading heading-6 dark-text font-poppings ellipis">
                                        {offer?.name}
                                    </h4>
                                    <div className="reviews">
                                        <div className="star">
                                            {[1, 2, 3, 4, 5].map((item, index) => {
                                                if (item <= Number(offer?.starRating))
                                                    return (
                                                        <FontAwesomeIcon
                                                            key={index}
                                                            icon={faStar}
                                                            className="filled"
                                                        />
                                                    );
                                                else
                                                    return (
                                                        <FontAwesomeIcon
                                                            key={index}
                                                            icon={faStar}
                                                            className="blank"
                                                        />
                                                    );
                                            })}
                                            <span>{`${offer?.guestRating || 0} (${offer?.guestReviewCount || 0})`}</span>
                                        </div>
                                    </div>
                                    <p className="location">
                                        <FontAwesomeIcon icon={faMapMarkerAlt}/>
                                        {offer?.address}
                                    </p>
                                    <div className="price-card">
                                        {getCurrencyBadge(offer?.perPersonPrice?.currency)}
                                        {Number(offer?.perPersonPrice?.totalPrice).toFixed(0)}
                                        <span>p.p</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}

                </Slider>
            </div>
        </>
    );
}

export default MapCardSlider;
